export function parseEnum<T>(enumType: T, value: string, defaultValue: T[keyof T]|undefined): T[keyof T] {
  const enumValues = Object.values(enumType as any);

  if (enumValues.includes(value)) {
    return value as T[keyof T];
  }

  if (defaultValue) {
    return defaultValue;
  }

  console.error(`Invalid enum value: ${value}`);

  return value as T[keyof T];
}