export interface RegisterBody {
  username: string;
  email: string;
  password: string;
}

export enum RegisterError {
  Unknown = "Unknown error",
  RegistrationDisabled = "Registration is disabled",
  UsernameTaken = "Username is already taken",
  EmailTaken = "Email is already taken",
  EmailInvalid = "Invalid email",
}

export function registerErrorToMessage(error: RegisterError): string {
  switch (error) {
    case RegisterError.RegistrationDisabled:
      return "Aktuell ist keine Registrierung möglich.";
    case RegisterError.UsernameTaken:
      return "Benutzername ist bereits vergeben";
    case RegisterError.EmailTaken:
      return "Email ist bereits vergeben";
    case RegisterError.EmailInvalid:
      return "Email ist ungültig";
    default:
      return "Unbekannter Fehler";
  }
}

export interface LoginBody {
  username: string;
  password: string;
}

export interface LoginTotpBody {
  state: string;
  code: string;
}

export interface LoginToken {
  token: string;
  expiresIn: number;
}

export interface OAuth2Data {
  app_name: string;
  app_icon: string;
}

export enum LoginErrorType {
  Unknown = "Unknown error",
  NotActivated = "User is not activated",
  OtpMissing = "OTP is missing",
  OtpWrong = "OTP is wrong",
  UserNotFound = "User not found",
  InvalidPassword = "Invalid password",
  UserBanned = "User is banned",
  InvalidState = "Invalid state"
}

export function loginErrorToMessage(error: LoginErrorType): string {
  switch (error) {
    case LoginErrorType.NotActivated:
      return "Benutzer ist nicht aktiviert";
    case LoginErrorType.OtpWrong:
      return "Zwei-Faktor-Code ist falsch";
    case LoginErrorType.UserNotFound:
      return "Benutzer nicht gefunden";
    case LoginErrorType.InvalidPassword:
      return "Passwort ist falsch";
    case LoginErrorType.UserBanned:
      return "Benutzer ist gebannt";
    default:
      return "Unbekannter Fehler";
  }
}

export interface LoginError {
  type: LoginErrorType;
}

export interface LoginErrorWithState extends LoginError {
  type: LoginErrorType.NotActivated|LoginErrorType.OtpMissing|LoginErrorType.OtpWrong;
  state: string;
}

export type LoginErrorUnion = LoginError | LoginErrorWithState;