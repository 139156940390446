import axios from 'axios';
import { getSession, setSession } from './jwt';
import type {ErrorResponse} from "@/rest/dto";

/**
 * A time in milliseconds which will be subtracted from the session's expiration time to determine if the session is expired.
 * It is used to prevent the session from expiring while the user is still using the application, so this offset should be.
 */
const EXPIRE_THRESHOLD = 10_000;

const client = axios.create({
  baseURL: import.meta.env.VITE_API_URL as string,
  withCredentials: true,
});
const refreshClient = axios.create({
  baseURL: import.meta.env.VITE_API_URL as string,
  withCredentials: true,
});

function extractTokenFromQuery(): string|null {
  const query = new URLSearchParams(window.location.search);
  return query.get('act');
}

client.interceptors.request.use(async (config) => {
  const act = extractTokenFromQuery();
  if (act) {
    config.headers.Authorization = `Bearer ${act}`;
    return config;
  }

  const session = getSession();
  if (session) {
    if (session.expires - EXPIRE_THRESHOLD < Date.now()) {
      const data = (await refreshClient.post<{token: string, expiresIn: number}>('/auth/refresh')).data;
      setSession(data.token, data.expiresIn);
      session.token = data.token;
    }

    config.headers.Authorization = `Bearer ${session.token}`;
  }

  return config;
})

export async function GET<T>(url: string, params?: {[key: string]: any}): Promise<T> {
  try {
    const response = await client.get<T>(url, { params });
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw new RestError(error.response.statusText, error.response.status, error.response.data);
    }

    throw error;
  }
}

export async function POST<T>(url: string, data?: any, params?: {[key: string]: any}): Promise<T> {
  try {
    const response = await client.post<T>(url, data, { params });
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw new RestError(error.response.statusText, error.response.status, error.response.data);
    }

    throw error;
  }
}

export async function PUT<T>(url: string, data?: any, params?: {[key: string]: any}): Promise<T> {
  try {
    const response = await client.put<T>(url, data, { params });
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw new RestError(error.response.statusText, error.response.status, error.response.data);
    }

    throw error;
  }
}

export async function DELETE<T>(url: string, params?: {[key: string]: any}): Promise<T> {
  try {
    const response = await client.delete<T>(url, { params });
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw new RestError(error.response.statusText, error.response.status, error.response.data);
    }

    throw error;
  }
}

export async function PATCH<T>(url: string, data?: any, params?: {[key: string]: any}): Promise<T> {
  try {
    const response = await client.patch<T>(url, data, { params });
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw new RestError(error.response.statusText, error.response.status, error.response.data);
    }

    throw error;
  }
}

export async function GET_FILE(url_: string, fileName: string): Promise<boolean> {
  try {
    const response = await client.get(url_, { responseType: 'blob' });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    return true;
  } catch (error: any) {
    if (error.response) {
      throw new RestError(error.response.statusText, error.response.status, error.response.data);
    }

    throw error;
  }
}

export class RestError<T = ErrorResponse> extends Error {
  constructor(message: string, public status: number, public data: T) {
    super(message);
  }
}
