import {defineStore} from "pinia";
import type {FullUser} from "@/@types/user";

export const useGeneralStore = defineStore('general', {
  state: () => ({
    arrivingHistoryLength: window.history.length,
    user: undefined as FullUser|undefined
  }),
  getters: {
    isLoggedIn(state) {
      return !!state.user;
    },
    historyLength(state) {
      return window.history.length - state.arrivingHistoryLength;
    }
  },
  actions: {
    setUser(user: FullUser|undefined) {
      this.user = user;
    }
  }
});