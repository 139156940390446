import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'

import { createVuestic } from 'vuestic-ui';
import '@fortawesome/fontawesome-free/css/all.css'
import 'vuestic-ui/css';
import '@/styles/index.scss';

const app = createApp(App)

/*Sentry.init({
    app,
    dsn: 'https://c0e8a2f09d1edf1ad5c5429dbd3a3c4a@sentry.sevenity.dev/4',
    integrations: [
        new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        })
    ],
    tracesSampleRate: 1
})*/

app.use(createVuestic({
    config: {
        colors: {
            currentPresetName: "dark"
        }
    }
}))
app.use(createPinia())
app.use(router)

app.mount('#app')
