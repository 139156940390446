import { PATCH, POST, PUT } from '@/rest/client';

export async function activateUser(userID: string, token: string): Promise<boolean> {
    try {
        await POST(`/users/${userID}/activate`, null, {
            act: token
        });
        return true;
    } catch (e) {
        console.error(e);
        return false;
    }
}

export async function resendActivationEmail(state: string): Promise<boolean> {
    try {
        await POST(`/users/activation/resend`, null, {
            state
        });
        return true;
    } catch (e) {
        console.error(e);
        return false;
    }
}

export async function requestPasswordReset(usernameOrEmail: string) {
    try {
        await POST(`/users/password-reset/request`, null, {
            known: usernameOrEmail
        });
        return true;
    } catch (e) {
        console.error(e);
        return false;
    }
}

export async function resetPassword(userID: string, token: string, newPassword: string, otp: string|undefined) {
    try {
        await PUT(`/users/${userID}/password-reset`, {
            password: newPassword,
            otp
        }, {
            ret: token
        });
        return true;
    } catch (e) {
        console.error(e);
        return false;
    }
}

export async function changePassword(oldPassword: string, newPassword: string, otp: string|undefined) {
    try {
        await PATCH(`/users/password`, {
            oldPassword,
            newPassword,
            otp
        });
        return true;
    } catch (e) {
        console.error(e);
        return false;
    }
}