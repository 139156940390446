import type {FullUser} from "@/@types/user";
import {GET} from "@/rest/client";

export async function getMyUser(): Promise<FullUser|undefined> {
  try {
    return await GET<FullUser>('/users/@me', {
      mode: "web",
      full: "true"
    });
  } catch (e) {
    console.error(e);
    return undefined;
  }
}